//React
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link as LinkRouter, useNavigate } from 'react-router-dom';

//Libraries
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { majorScale, minorScale, Pane, Text, UnorderedList, ListItem, SideSheet, Position, Menu, Popover, Dialog, useTheme } from 'evergreen-ui';

//Files
import { accountTypes } from '../../utils/constants';
import { actions } from '../../store/actions';
import { getTopLevelAccount } from '../../utils/functions';

//Components
import Button from '../../components/ui/Button/Button';
import IconWrapper from '../../components/Icons/Icons'
import CustomHeading from '../../components/Headings/Headings';
import CustomDialog from '../../components/Dialog/Dialog';
import PermissionChecker from '../../components/PermissionChecker/PermissionChecker';
import theme from '../../theme';


//Nav Icon
const NavIcon = (props) => {
  const { isActive, name, ...otherProps } = props;
  const theme = useTheme();

  return (
    <Pane 
      display="flex" 
      alignItems="center" 
      justifyContent="center" 
      height={32}
      lineHeight="0"
      flexShrink={0} 
      marginLeft={majorScale(1)}>
    <IconWrapper
        {...otherProps}
        name={name}
        color={theme.colors.black}
        size={16}
    />
    </Pane>
  );
};

//Nav Text
const NavText = (props) => {
  const { isActive, children, ...otherProps } = props;
  const theme = useTheme();

  return (
    <Text
        {...otherProps}
        color={theme.colors.black}
        flex="1 0 auto"
        fontSize="16px"
        fontWeight="400"
        lineHeight="24px"
        marginLeft={majorScale(1)}
    >
      {children}
    </Text>
  )
};

//Nav Link
const NavLink = (props) => {
  const { icon, label, to, account, onClick, ...rest } = props;

  const dispatch = useDispatch();
  const currentAccount = useSelector((state) => state.currentAccount);
  const activeAccount = useSelector((state) => (state.workingAccount || state.currentAccount));

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const accountMatches = (account || currentAccount) === activeAccount;
  const isActive = location.pathname === to || (to !== '/' && location.pathname.startsWith(to));

  const paneStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: '32px',
    backgroundColor: isActive ? theme.colors.primary100 : 'transparent', 
    borderRadius: '5px',
  };

  const linkStyle = {
    textDecoration: "none",
  };

  const setWorkingAccount = (accountId) => {
    dispatch(actions.workingAccountSet(accountId));
  };
  const closeNavBar = () => {
    dispatch(actions.navMenuExpandedSet(false));
  };
  const handleClick = (e) => {
    e.preventDefault();
    if (account && !accountMatches) {
      setWorkingAccount(account);
    }
    closeNavBar();
    if (onClick) {
      onClick(e);
    }
    if (to) {
      navigate(to);
    }
  };

  return (
    <LinkRouter
        {...rest}
        to={to}
        is={LinkRouter}
        onClick={handleClick}
        style={linkStyle}
        >
      <Pane style={paneStyle}>
        {icon && <NavIcon isActive={isActive} name={icon} />}
        {label && <NavText isActive={isActive} >{label}</NavText>}
      </Pane>
  </LinkRouter>
    
  );
};


//Nav Group
const NavGroup = (props) => {
  const { children, icon, label } = props;
  const location = useLocation();

  const currentAccount = useSelector((state) => state.currentAccount);
  const activeAccount = useSelector((state) => (state.workingAccount || state.currentAccount));

  const account = props.account || currentAccount;
  const accountMatches = activeAccount === account;

  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(isActive);

  useEffect(() => {
    const isActive = children.some(child => {
      const childHref = child.props.to;
      return (childHref === location.pathname) || (childHref && location.pathname.startsWith(childHref));
    }) && accountMatches;

    setIsOpen(isActive);
  }, [location, children, accountMatches]);

  return (
    <React.Fragment>
      <Pane
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height='28px'
          style={{ cursor: 'pointer' }}
          onClick={() => setIsOpen(!isOpen)}
      >
        {/*<NavIcon name={icon} isActive={isActive} />*/}
        <CustomHeading level="2">    
          {label}
        </CustomHeading>
        <IconWrapper
            size={15}
            name={isOpen ? 'caretUp' : 'caretDown'}
            color={theme.colors.secondary70}
            marginRight={majorScale(2)}
        />
      </Pane>

      <Pane 
        height={isOpen ? 'auto' : 0} 
        overflow="hidden">
        <UnorderedList 
          listStyle="none" 
          marginLeft={minorScale(1)} 
          padding={0}
        >
          {children && React.Children.map(children, (item, index) => item && (
            <ListItem margin={0} key={index} minWidth='auto'>
              {(item.type === NavLink) ? React.cloneElement(item, { account: account }) : item}
            </ListItem>
          ))}
        </UnorderedList>
      </Pane>
    </React.Fragment>
  );
};

//Nav Menu that encompass everything
const NavMenu = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLoading = useSelector((state) => state.backgroundLoading);

  const user = useSelector((state) => state.user);

  //This is to get all the accounts available for an OWNER account
  const userAccounts = useSelector((state) => {
    const headOffice = getTopLevelAccount();
    return _.keyBy(
      _.filter(
        state.accounts,
        (account) => (
          account.id === headOffice.id ||
          _.includes(account.parents, headOffice.id) ||
          account.type === accountTypes.SUPPLIER)
      ),
      'id'
    );
  });

  //Location or Head-office
  const currentAccount = useSelector((state) => state.currentAccount);
  const account = useSelector((state) => state.accounts[currentAccount]);
  //console.log(currentAccount, 'CURRENTACCOUNT NAV')

  //Department else false (if OWNER)
  const workingAccount = useSelector((state) => {
    if (state.workingAccount) {
      return _.find(state.accounts, { 'id': state.workingAccount });
    }
    return false;
  });

  const navMenuExpanded = useSelector((state) => state.navMenuExpanded);
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 460 });

  const accountGroups = _.groupBy(userAccounts, (account) => (account.type || accountTypes.DEPARTMENT));
  const isSupplier = userAccounts[currentAccount] && userAccounts[currentAccount].type && userAccounts[currentAccount].type === accountTypes.SUPPLIER;

  //Create a Dialog for when a user enter an area
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDeptDialogShown, setIsDeptDialogShown] = useState(false);

  // Show the dialog on component mount
  useEffect(() => {
    setIsDeptDialogShown(true);
  }, []);

  const areas = useSelector((state) => {
    const currentAccount = state.currentAccount;
    const account = _.find(state.accounts, { 'id': currentAccount });
    const isHeadOffice = (account && account.type === accountTypes.OWNER);

    return _.sortBy(
      _.filter(
        state.accounts,
        (dept) => (
          ((isHeadOffice && !dept.departments) || !isHeadOffice) && 
          (!dept.type || dept.type === accountTypes.DEPARTMENT) && 
          _.includes(dept.parents, currentAccount)
        )
      ),
      'name'
    );
  });

  const selectDept = useCallback((dept) => {
      setIsDeptDialogShown(false);
      dispatch(actions.workingAccountSet(dept.id));
      navigate(`/${dept.id}/front-of-house`);
      setIsPopoverOpen(false);
  }, [dispatch])

  useEffect(() => {
    if (userAccounts[currentAccount] && userAccounts[currentAccount].type && userAccounts[currentAccount].type === accountTypes.LOCATION) {
      if (!workingAccount && areas.length === 1) {
        selectDept(areas[0])
      }
    }
  }, [areas, userAccounts, currentAccount, workingAccount, dispatch, selectDept]);  

  if (!user || isMobile) {
    return null;
  }

  const selectAccount = ({ value }) => {
    dispatch(actions.workingAccountClear());
    dispatch(actions.profile.profileSetAccount(value));
    navigate('/');
  };

  const closeNavBar = () => {
    dispatch(actions.navMenuExpandedSet(false));
  };
  
  // Custom footer for the dialog
  const customFooter = (
    <Pane display="flex" flexWrap="wrap" justifyContent="space-between" paddingBottom={majorScale(2)}>
      {_.map(areas, (area) => (
        <Button
          key={area.id}
          appearance='primary'
          onClick={() => selectDept(area)}
          marginX={majorScale(1)} 
          marginBottom={majorScale(1)}
          width="180px"
          maxWidth="220px"
          overflow= 'hidden'
        >
          <Text 
            color={theme.colors.black}
            fontSize="16px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            maxWidth="100%"
            display= 'block' 
          >
            {area.area?.name}
          </Text>
        </Button>
      ))}
    </Pane>
  );

  const contents = (
    <Pane
      is="nav"
      width={220}
      paddingX="15px" 
      maxHeight="100%"
      overflowY="auto"
      background={theme.colors.offwhite}
    >

      {/* Area Selection Dialog for LOCATION type accounts */}
      {isDeptDialogShown && userAccounts[currentAccount]?.type === accountTypes.LOCATION && (
        <CustomDialog
          isOpen={true}
          title="Select an area"
          onCloseComplete={() => setIsDeptDialogShown(false)}
          confirmLabel="Select"
          hasFooter={false} 
          foot={customFooter}
        >
        </CustomDialog>
      )}

      {/* Logo */}
      <Pane 
        height={60} 
        display="flex" 
        alignItems="center"
        marginBottom={majorScale(1)}
        marginLeft={majorScale(1)}
        justifyContent="start" 
        //border="1px solid red"
      >
        <img src={`img/logo-blue.png`} alt="Logo" style={{ maxHeight: '80%', maxWidth: '100%', height: 'auto', width: 'auto' }} />
      </Pane>
    
      {/* Unified Area Selection or Information Display */}
      <Pane 
        marginBottom={minorScale(1)} 
        border="none">
        {userAccounts[currentAccount] && (
          <>
            {(userAccounts[currentAccount]?.type === accountTypes.LOCATION) && (
              <Popover
                position={Position.BOTTOM_LEFT}
                isShown={isPopoverOpen}
                onOpen={() => setIsPopoverOpen(true)}
                onClose={() => setIsPopoverOpen(false)}
                content={
                  <Menu>
                    <Menu.Group>
                      {areas.map((area) => (
                        <Menu.Item
                          key={area.id}
                          onSelect={() => selectDept(area)}
                        >
                          {area.area.name}
                        </Menu.Item>
                      ))}
                    </Menu.Group>
                  </Menu>
                }
              >
                <Button
                  appearance='navMainButton'
                  height={40}
                  width="100%"
                  border="none"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  onClick={() => setIsPopoverOpen(true)} 
                >
                  <Text
                    fontSize="16px"
                    color={theme.colors.black}
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    textAlign="start"
                    width="100%"
                    fontWeight="400"
                  >
                    {workingAccount?.name ? workingAccount?.area?.name : 'Select Area'}
                  </Text>
                </Button>
              </Popover>
            )}

            {(userAccounts[currentAccount]?.type === accountTypes.OWNER || userAccounts[currentAccount]?.type === accountTypes.SUPPLIER) && (
              // Display as a static Pane for OWNER and SUPPLIER accounts
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="start"
                height={40}
                borderRadius="5px"
                backgroundColor={theme.colors.secondary10}
                width="100%"
                border="none"
              >
                <Text
                  marginX={majorScale(2)}
                  color={theme.colors.black}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  width="100%"  
                  fontSize="16px"
                  fontWeight="400"
                >
                  {userAccounts[currentAccount].name}
                </Text>
              </Pane>
            )}
          </>
        )}
      </Pane>


      {/* Change Account - Head-Office or Location */}
      <Pane marginBottom={majorScale(2)} >
      <PermissionChecker requiredPermission="changeAccount">
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              {_.map(isSupplier ? [accountTypes.SUPPLIER] : [accountTypes.OWNER, accountTypes.LOCATION], (group) => {
                const title = group === accountTypes.LOCATION ?
                  'Locations' :
                  group === accountTypes.OWNER ?
                  'Head Office' :
                  'Suppliers';
                return (
                  <Menu.Group title={title} key={group}>
                    {_.map(accountGroups[group], (account) => (
                      <Menu.Item
                          key={account.id}
                          onSelect={() => selectAccount({ value: account.id })}
                      >{account.name}</Menu.Item>
                    ))}
                  </Menu.Group>
                )
              })}
            </Menu>
          }
        >
          <Text
            marginLeft={majorScale(1)}
            style={{ fontSize: '14px', textDecoration: 'underline', cursor: 'pointer' }}
          >
            Change account
          </Text>
        </Popover>
      </PermissionChecker>
      </Pane>

      {/* HEAD OFFICE OPTIONS */}
      {(userAccounts[currentAccount]?.type && userAccounts[currentAccount]?.type === accountTypes.OWNER) && (
          <>
            <UnorderedList
                listStyle="none"
                marginLeft={0}
                marginY={0}
            >
              {
                isLoading ? 
                  <CustomHeading level="2" marginBottom={majorScale(4)}>ANALYSE</CustomHeading>
                  : (
                  <>
                    <CustomHeading level="2">ANALYSE</CustomHeading>
                    <ListItem marginLeft={minorScale(1)}>
                      <PermissionChecker requiredPermission="dashboard">
                        <NavLink to="/analytics/dashboard" icon="dotsNine" label="Dashboard" />
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="financials">
                        <NavLink to="/analytics/financials" icon="chartBar" label="Financials"/>
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="sales">
                        <NavLink to="/analytics/sales" icon="cashRegister" label="Sales"/>
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="costs">
                        <NavLink to="/analytics/costs" icon="coinVertical" label="Costs"/>
                      </PermissionChecker>
                    </ListItem>
                  </>
                )
              }

              <CustomHeading level="2">MANAGE</CustomHeading> 
              <ListItem marginLeft={minorScale(1)}>
                <PermissionChecker requiredPermission="products">
                  <NavLink to="/products" icon="squareFour" label="Products" />
                </PermissionChecker>
                <PermissionChecker requiredPermission="ingredients">
                  <NavLink to="/ingredients" icon="carrot" label="Ingredients" account={currentAccount} />
                </PermissionChecker>
                <PermissionChecker requiredPermission="suppliers">
                  <NavLink to="/suppliers" icon="windmill" label="Suppliers" account={currentAccount} />
                </PermissionChecker>
                <PermissionChecker requiredPermission="marketplace">
                  <NavLink to="/marketplace" icon="barn" label="Marketplace" account={currentAccount} />
                </PermissionChecker>
              </ListItem>

              <CustomHeading level="2">SETUP</CustomHeading> 
              <ListItem marginLeft={minorScale(1)} marginBottom={majorScale(2)}>
                <PermissionChecker requiredPermission="account">
                  <NavLink to="/account" icon="building" label="Head office" account={currentAccount} />
                </PermissionChecker>
                <PermissionChecker requiredPermission="locations">
                  <NavLink to="/locations" icon="buildingOffice" label="Locations" />
                </PermissionChecker>
              </ListItem>
            </UnorderedList>
          </>
        )}

      {/* SUPPLIER OPTIONS */}
      {isSupplier && (
          <>
              <Pane
                  marginBottom={majorScale(2)}
                  border="muted"
              >
                <Pane
                    background="tint1"
                    height={48}
                    paddingX={majorScale(2)}
                    display="flex"
                    alignItems="center"
                >
                  <CustomHeading level="2">
                    {userAccounts[currentAccount].name}
                  </CustomHeading>
                </Pane>

                <UnorderedList
                    listStyle="none"
                    marginLeft={0}
                    paddingY={0}
                    paddingX={majorScale(2)}
                >
                  <ListItem marginLeft={minorScale(1)}>
                    <NavLink to={`/`} icon="windmill" label="Supplier" account={userAccounts[currentAccount].id} />
                    <NavLink to="/account" icon="building" label="Settings" account={currentAccount} />
                  </ListItem>
                </UnorderedList>
              </Pane>
          </>
        )}

        {/* LOCATION OPTIONS */}
        {(userAccounts[currentAccount]?.type && userAccounts[currentAccount]?.type === accountTypes.LOCATION) && (
          <>
            {workingAccount && workingAccount.id !== currentAccount && (
              <Pane
                  //marginBottom={majorScale(2)}
                  border="none"
              >
                <UnorderedList
                    listStyle="none"
                    marginLeft={0}
                    marginY={0}
                >
                  {/*Not there yet, but need to adjust the formula on how we buildAccounts to only start at Location instead of trying to aggregate everything
                  Should work fine then
                  <PermissionChecker requiredPermission="analyticsLocation">
                    <CustomHeading level="2">ANALYSE</CustomHeading> 
                    <ListItem marginLeft={minorScale(1)}>
                      <NavLink to={`/analytics/dashboard`} icon="dotsNine" label="Dashboard" account={workingAccount.id} />
                      <NavLink to={`/${workingAccount.id}/analytics/financials`} icon="chartBar" label="Financials" account={workingAccount.id} />
                      <NavLink to={`/analytics/sales`} icon="cashRegister" label="Sales" account={currentAccount} />
                      <NavLink to={`/${workingAccount.id}/analytics/costs`} icon="coinVertical" label="Costs" account={workingAccount.id} />
                    </ListItem>
                  </PermissionChecker>*/}
                  <PermissionChecker requiredPermission="analyticsLocation">
                  <CustomHeading level="2">ANALYSE</CustomHeading> 
                  <ListItem marginLeft={minorScale(1)} >
                      <NavLink to={`/${workingAccount.id}/reports/overview`} icon="dotsNine" label="Overview" account={workingAccount.id} /> 
                      <NavLink to={`/${workingAccount.id}/reports/gross-profit`} icon="piggyBank" label="Gross Profit"/>
                      <NavLink to={`/${workingAccount.id}/reports/sales`} icon="cashRegister" label="Sales" account={workingAccount.id} />
                      <NavLink to={`/${workingAccount.id}/reports/costs`} icon="coinVertical" label="Costs" account={workingAccount.id} />
                  </ListItem>
                  </PermissionChecker>

                  <CustomHeading level="2">OPERATE</CustomHeading> 
                  <ListItem marginLeft={minorScale(1)} >
                    <PermissionChecker requiredPermission="tasksLocation">
                      <NavLink to={`/${workingAccount.id}/tasks`} icon="checkFat" label="Tasks & HACCP" />
                    </PermissionChecker>
                    <PermissionChecker requiredPermission="productsLocation">
                      <NavLink to={`/${workingAccount.id}/front-of-house`} icon="squareFour" label="Product Hub" />
                    </PermissionChecker>
                    <PermissionChecker requiredPermission="procurementLocation">
                      <NavLink to={`/${workingAccount.id}/procurement`} icon="shoppingCart" label="Procurement" />
                    </PermissionChecker>
                    <PermissionChecker requiredPermission="inventoryLocation">
                      <NavLink to={`/${workingAccount.id}/inventory`} icon="bookMarks" label="Inventory" />
                    </PermissionChecker>
                  </ListItem>

                  <CustomHeading level="2">REVIEW</CustomHeading> 
                  <ListItem marginLeft={minorScale(1)} >
                      <PermissionChecker requiredPermission="ordersReviewLocation">
                        <NavLink to={`/${workingAccount.id}/review/supplier-statements`} icon="shoppingBag" label="Orders" />
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="stocktakesReviewLocation">
                        <NavLink to={`/${workingAccount.id}/review/stocktake`} icon="package" label="Stocktakes" />
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="wastagesReviewLocation">
                        <NavLink to={`/${workingAccount.id}/review/wastage`} icon="recycle" label="Wastages" />
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="transfersReviewLocation">
                        <NavLink to={`/${workingAccount.id}/review/transfers`} icon="arrowH" label="Transfers" />
                      </PermissionChecker>
                      <PermissionChecker requiredPermission="dueDiligenceReviewLocation">
                        <NavLink to={`/${workingAccount.id}/review/due-diligence`} icon="glassPlus" label="Due Diligence" />
                      </PermissionChecker>
                  </ListItem>
                </UnorderedList>
              </Pane>
            )}
          </>
        )}

        {/* LOCATION For non specific account */}
        {/*<UnorderedList listStyle="none"
            //marginBottom={majorScale(2)}
            marginLeft={0}
            paddingY={0}
        >
          {/*{(![accountTypes.LOCATION, accountTypes.OWNER, accountTypes.SUPPLIER].includes(userAccounts[currentAccount]?.type)) && (
            <>
              <ListItem margin={0}>
                <NavLink to="/reports" icon="chart" label="Reports" />
              </ListItem>
              <ListItem margin={0}>
                <NavGroup icon="dashboard" label="Workspace">
                  <NavLink to="/tasks" label="Tasks & HACCP" />
                  <NavLink to="/front-of-house" label="Menu Book" />
                  <NavLink to="/ordering" label="Ordering" />
                  <NavLink to="/stocks" label="Stocks" />
                </NavGroup>
              </ListItem>
              <ListItem margin={0}>
                <NavGroup icon="folderClose" label="Reports">
                  <NavLink to="/archive/reports" label="GP analysis" />
                  <NavLink to="/archive/supplier-statements" label="Orders by supplier" />
                  <NavLink to="/archive/stock-take" label="Stocktakes" />
                  <NavLink to="/archive/wastage" label="Wastages" />
                  <NavLink to="/archive/due-diligence" label="Due Diligence" />
                </NavGroup>
              </ListItem>
            </>
          )}

              <ListItem margin={0}>
                <NavLink to="/account" icon="settings" label="Head office" account={currentAccount} />
              </ListItem>
          </UnorderedList>*/}
    </Pane>
  );

  if (!userAccounts[currentAccount]) return null;

  if (isTablet) {
    return (
      <SideSheet
          isShown={navMenuExpanded}
          onCloseComplete={closeNavBar}
          width={230}
          position={Position.LEFT}
          containerProps={{ display: 'flex' }}
      >
        {contents}
      </SideSheet>
    );
  }
  else {
    return contents;
  }
};

export default NavMenu;
