import store from 'store/redux';
import _ from 'lodash';
import { accountTypes } from './constants';
import { buildGroupRecipes } from './departments';
import { buildRecipeCategory, recipeCosting } from './functions';

// Determines whether an item of a specific type should be shown based on the applied filters.
// Returns true if the filter is not set or if the item matches the filter criteria.
const filterShowType = (type, match, filters) => {
  if (!filters[type] ||
      filters[type].length === 0 ||
      filters[type].indexOf(match) > -1
  ) {
    return true;
  }
  return false;
}

// Processes locations and their children to organize area-related data. 
// It identifies department-type children within a location and applies filters to them.
const buildLocationAreas = (location, state, data, filters) => {
  if (location.children) {
    for (const child of location.children) {
      const childAcc = state.accounts[child];
      if (childAcc?.type === accountTypes.DEPARTMENT) {
        const area = {...childAcc, location}
        data.allAreas.push(area);
        if (filterShowType('areas', childAcc.name, filters)) {
          data.areas.push(area);
        }
      }
    }
  }
  return data;
}

// Iteratively constructs location area data for account children, specifically handling location-type accounts.
// Applies filters to determine which locations and their areas are relevant.
const buildAccountLocationAreas = (accountChild, state, data, filters) => {
  if (accountChild && accountChild.type === accountTypes.LOCATION) {
    if (filterShowType('locations', accountChild.name, filters)) {
      data.locations.push(accountChild);
      data = buildLocationAreas(accountChild, state, data, filters)
    }
    data.allLocations.push(accountChild)
  }
  return data;
}

// Organizes and filters categories related to a department, adding them to the respective data structures.
// Handles both modifier and recipe categories.
const buildCats = (cats, department, data, filters) => {
  for (const cat of cats) {
    const category = {...cat, department};
    data.allCategories.push(category);
    if (filterShowType('categories', category.name, filters)) {
      data.categories.push(category);
    }
  }
  return data;
};

// Compiles and filters recipes associated with a department, categorizing them and incorporating into data structures.
// Applies category-based filtering to include relevant recipes.
const buildRecipes = (accountId, recipes, department, data, filters, parent) => {
  for (const rec of recipes) {
    const recipe = buildRecipeCategory({...rec, department, accountId}, data.categories);

    // Calculate recipe cost
    recipe.recipeCost = recipeCosting(recipe, false, true);

    data.allRecipes.push(recipe);
    if (filterShowType('categories', recipe.category, filters)) {
      data.recipes.push(recipe);
    }
  }
  return data;
};

// Determines if a department should be included based on its association with filtered areas.
// Returns true if the department is related to any selected area or if no area-specific filtering is applied.
const filterDepartment = (department, data) => {
  if (data.areas.length > 0) {
    if (data.areas.findIndex(a => a.departments.indexOf(department.id) > -1) > -1) {
      return true
    }

    return false;
  }

  return true;
}

// Aggregates data for account categories, specifically handling department-related entities.
// Applies filters to include only relevant departments and their associated data.
const buildAccountCategories = (accountChild, state, data, filters, parent) => {
  if (accountChild && accountChild.type === accountTypes.DEPARTMENT) {
    if (!filterDepartment(accountChild, data)) return data;

    data.allDepartments.push(accountChild)
    if (filterShowType('departments', accountChild.name, filters)) {
      data.departments.push(accountChild);
      const modCats = state.modifierCategories[accountChild.id];
      const recipeCats = state.recipeCategories[accountChild.id];
      const recipes = state.recipes[accountChild.id];
      const modifiers = state.modifiers[accountChild.id];
      if (modCats?.length > 0) {
        data = buildCats(modCats, accountChild, data, filters);
      }
      if (recipeCats?.length > 0) {
        data = buildCats(recipeCats, accountChild, data, filters);
      }
      if (recipes?.length > 0) {
        data = buildRecipes(data.accountId, recipes, accountChild, data, filters, parent);
      }
      if (modifiers?.length > 0) {
        data = buildRecipes(data.accountId, modifiers, accountChild, data, filters);
      }
    }
  }
  return data;
}

// Initializes a data structure to store aggregated account-related information, setting up initial state for data collection.
const buildInitState = (accountId) => ({
  accountId,
  locations: [],
  allLocations: [],
  areas: [],
  allAreas: [],
  categories: [],
  allCategories: [],
  departments: [],
  allDepartments: [],
  recipes: [],
  allRecipes: [], 
})

// Constructs comprehensive account-related data by aggregating information from various sources.
// Applies filters and organizes data into structured format for application use.
export const buildAccountData = (accountId, filters) => {
  const init = buildInitState(accountId);
  const state = store.getState();
  const account = state.accounts[accountId];

  if (account?.children) {
    const initLocations = account.children.reduce((data, child) => {
      const childAcc = state.accounts[child];
      data = buildAccountLocationAreas(childAcc, state, data, filters);
      return data;
    }, init);

    return account.children.reduce((data, child) => {
      const childAcc = state.accounts[child];
      data = buildAccountCategories(childAcc, state, data, filters, account);
      return data;
    }, initLocations);
  }
  return init;
}

// Determines the set of accessible account IDs for a given account, considering hierarchical relationships and account types.
// Facilitates access control by identifying all relevant accounts within the organization's structure.
export const getAccessibleAccountIds = (accountId = false) => {
  const accounts = _.keyBy(store.getState().accounts, 'id');
  const currentAccountId = accountId || store.getState().currentAccount;

  // Function to recursively collect child account IDs
  const collectChildAccountIds = (accountId, collectedIds = []) => {
    const account = accounts[accountId];
    if (!account || !account.children) {
      return;
    }

    account.children.forEach(childId => {
      if (!collectedIds.includes(childId)) {
        collectedIds.push(childId);
        collectChildAccountIds(childId, collectedIds); // Recurse for children
      }
    });
  };

  let accessibleAccountIds = [];

  // Check if the current account is of type OWNER
  if (accounts[currentAccountId] && accounts[currentAccountId].type === accountTypes.OWNER) {
    accessibleAccountIds.push(currentAccountId);
    collectChildAccountIds(currentAccountId, accessibleAccountIds);
  } else {
    // If not OWNER, just return the current account ID
    accessibleAccountIds.push(currentAccountId);
  }

  // Additional logic to handle LOCATION type accounts
  let locationChildIds = [];
  accessibleAccountIds.forEach(accountId => {
    if (accounts[accountId] && accounts[accountId].type === accountTypes.LOCATION) {
      collectChildAccountIds(accountId, locationChildIds);
    }
  });

  // Combine and deduplicate IDs
  accessibleAccountIds = [...new Set([...accessibleAccountIds, ...locationChildIds])];

  return accessibleAccountIds;
};

//Only used in Redux and Firebase, may need to adjust the name down the road
export const getAccessibleAccountIds2 = (accountId = false) => {
  const accounts = _.keyBy(store.getState().accounts, 'id'); // Index accounts by ID
  const currentAccountId = accountId || store.getState().currentAccount;

  if (!accounts || !currentAccountId) {
    //console.log('[getAccessibleAccountIds2] Missing accounts or current account ID.');
    return [];
  }

  const currentAccount = accounts[currentAccountId];

  if (!currentAccount) {
    console.log('[getAccessibleAccountIds2] Current account not found in Redux state.');
    return [{ id: currentAccountId, type: 'UNKNOWN' }];
  }
  //console.log('[getAccessibleAccountIds2] Current Account:', currentAccount);

  // Function to find the top-most OWNER or SUPPLIER account
  const findRootAccount = (allAccounts, currentAccount) => {
    if (!allAccounts || !currentAccount) {
      //console.log('[findRootAccount] Invalid input: allAccounts or currentAccount is missing.');
      return null;
    }

    let current = currentAccount;

    while (current && current.parents && current.parents.length > 0) {
      const parentId = current.parents[0]; // Assume only one parent
      current = allAccounts[parentId];
      if (!current) {
        console.log(`[findRootAccount] Parent account not found: ${parentId}`);
        return null; // Break if parent not found
      }
    }

    if (current && (current.type === 'OWNER' || current.type === 'SUPPLIER')) {
      //console.log(`[findRootAccount] Found root account: ${current.id}, type: ${current.type}`);
      return current; // Return the OWNER or SUPPLIER account object
    }

    //console.log('[findRootAccount] No OWNER or SUPPLIER account found.');
    return null;
  };

  // Function to recursively collect child accounts
  const collectChildAccounts = (accountId, collectedAccounts = [], parentLocation = null) => {
    const account = accounts[accountId];
    if (!account || !account.children) {
      //console.log(`[collectChildAccounts] No children found for account: ${accountId}`);
      return;
    }

    account.children.forEach((childId) => {
      const childAccount = accounts[childId];
      if (childAccount && !collectedAccounts.find((acc) => acc.id === childId)) {
        const childType = account.type === 'OWNER' && childAccount.type === 'DEPARTMENT' ? 'GROUP' : childAccount.type;

        // Include parentLocation for DEPARTMENT accounts
        const childData = { 
          id: childId, 
          type: childType, 
          parentLocation: childType === 'DEPARTMENT' ? accountId : null 
        };

        //console.log(`[collectChildAccounts] Adding child account: ${childId} with data:`, childData);
        collectedAccounts.push(childData);

        // Recursively collect children
        collectChildAccounts(childId, collectedAccounts, childData.parentLocation || parentLocation);
      }
    });
  };

  // Step 1: Find the root account (OWNER or SUPPLIER)
  const rootAccount = findRootAccount(accounts, currentAccount);
  if (!rootAccount) {
    console.warn('[getAccessibleAccountIds2] No root account found; returning current account only.');
    return [{ id: currentAccountId, type: currentAccount?.type || 'UNKNOWN' }];
  }
  //console.log(`[getAccessibleAccountIds2] Root account found: ${rootAccount.id}, type: ${rootAccount.type}`);

  // Step 2: Collect all child accounts starting from the root account
  let accessibleAccounts = [{ id: rootAccount.id, type: rootAccount.type }];
  collectChildAccounts(rootAccount.id, accessibleAccounts);

  // Deduplicate and return the final list
  const uniqueAccounts = _.uniqBy(accessibleAccounts, 'id');
  //console.log('[getAccessibleAccountIds2] Final accessible accounts:', uniqueAccounts);
  return uniqueAccounts;
};

export const findOwnerAccount = (allAccounts, currentAccount) => {
  // Convert the accounts object to an array of values
  const accountsArray = Object.values(allAccounts);
  
  // Traverse up the parent hierarchy to find the OWNER account
  let current = currentAccount;
  while (current && current.parents && current.parents.length > 0) {
    current = allAccounts[current.parents[0]];
  }
  return current && current.type === 'OWNER' ? current : null;
};

/**
 * This function retrieves the relevant department accounts associated with the current account in the application's state.
 * It differentiates between head office (OWNER) accounts and department (DEPARTMENT) accounts, 
 * filtering and sorting them based on their relevance to menus, recipes, or modifiers.
 *
 * @param {object} state - The application's state.
 * @returns {array} - A sorted array of relevant department accounts.
 */

export const getGroupDepartments = (state) => {
  const currentAccountId = state.currentAccount;
  const currentAccount = state.accounts[currentAccountId];

  if (!currentAccount) {
    return [];
  }

  if (currentAccount.type === accountTypes.OWNER) {
    // If the account is a head office, get its children accounts as departments
    const childrenDepartments = currentAccount.children
      ? currentAccount.children
          .map((childId) => state.accounts[childId])
          .filter((dept) => dept && dept.type === accountTypes.DEPARTMENT &&
                            (state.menus[dept.id] || state.recipes[dept.id] || state.modifiers[dept.id]))
      : [];
    return _.sortBy(childrenDepartments, 'name');
  } else {
    // If not a head office, return any account that lists the current account as its parent
    const parentDepartments = _.filter(
      state.accounts,
      (dept) =>
        dept.type === accountTypes.DEPARTMENT &&
        dept.parents &&
        dept.parents.includes(currentAccountId) &&
        (state.menus[dept.id] || state.recipes[dept.id] || state.modifiers[dept.id])
    );
    return _.sortBy(parentDepartments, 'name');
  }
};
