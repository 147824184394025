const ACCOUNTS_DATA_SET = 'ACCOUNTS_DATA_SET';
const SALES_DATA_SET = 'SALES_DATA_SET';
const COSTS_DATA_SET = 'COSTS_DATA_SET';
const FINANCIALS_DATA_SET = 'FINANCIALS_DATA_SET';
const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';

export const actionTypes = {
    ACCOUNTS_DATA_SET,
    SALES_DATA_SET,
    COSTS_DATA_SET,
    FINANCIALS_DATA_SET,
    CLEAR_REPORT_DATA,
}

const accountsDataSet = (data) => {
    return {
        type: ACCOUNTS_DATA_SET,
        payload: data,
  }};

const salesDataSet = (data) => {
    return {
        type: SALES_DATA_SET,
        payload: data,
}};

const costsDataSet = (data) => {
    return {
        type: COSTS_DATA_SET,
        payload: data,
}};

const financialsDataSet = (data) => {
    return {
        type: FINANCIALS_DATA_SET,
        payload: data,
}};

const clearReportData = () => {
    return {
        type: CLEAR_REPORT_DATA,
  }};
  

export const actions = {
    accountsDataSet,
    salesDataSet,
    costsDataSet,
    financialsDataSet,
    clearReportData,
}