import _ from 'lodash';
import store from '../redux';
import fb from './'
import { actions } from '../actions';

let accountUsersListener = {};
const accountUsersRef = fb.firestore.collection('accountUsers')

// exported methods & fields//
// Loads and tracks real-time updates for users associated with a specific account.
//
// Purpose:
// - This function attaches a Firestore listener to the `accountUsers` collection for the specified `accountId`.
// - Ensures that changes to account memberships (e.g., adding, removing, or updating users) are reflected in the app in real time.
//
// Key Features:
// 1. **Dynamic Role Management**: 
//    - Tracks updates to roles, permissions, and memberships dynamically.
//    - Example: If a user is added, removed, or their role changes, the app state updates immediately.
//
// 2. **Improved Security and Collaboration**:
//    - Ensures accurate, up-to-date information on who has access to the account.
//    - Supports real-time revocation of access if needed.
//
// 3. **Efficient Listener Management**: 
//    - Removes any existing listener for the specified account before attaching a new one.
//    - Prevents duplicate listeners or stale data issues.
//
// How It Works:
// - Fetches all users linked to the `accountId` from the `accountUsers` collection in Firestore.
// - Dispatches `accountUsersLoadSuccess` to update the Redux state with the fetched users.
// - Dispatches `applicationLoadStart` and `applicationLoadComplete` to track the loading process.
//
// Parameters:
// - `accountId` (string): The unique identifier of the account whose users are being loaded.
//
// Example Use Case:
// - An admin adds or removes a user from an account. The app reflects this change immediately without needing a manual refresh.
//
export const loadAccountUsers = (accountId) => {
  if (accountUsersListener[accountId]) {
    //For debugging purpose
    //console.log(`Detaching existing listener for account users: ${accountId}`);
    accountUsersListener[accountId]();
  }
  //For debugging purpose
  //console.log(`Loading account users for: ${accountId}`);
  store.dispatch(actions.applicationLoadStart(`${accountId}-accountUsers`));
  accountUsersListener[accountId] = accountUsersRef
    .where('account', '==', accountId)
    .onSnapshot((querySnapshot) => {
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      //For debugging purpose
      //console.log(`Loaded ${results.length} users for account: ${accountId}`);
      store.dispatch(actions.accountUsers.accountUsersLoadSuccess(results, accountId));
      store.dispatch(actions.applicationLoadComplete(`${accountId}-accountUsers`));
    });
};

// Clears all local listeners for account user data.
export const clearAccountUsers = () => {
  _.forIn(accountUsersListener, (listener) => listener());
  store.dispatch(actions.accountUsers.accountUsersClear());
};

// Adds a new user to the specified account.
export const addToAccount = (accountUser) => {
  return accountUsersRef.add(accountUser);
};

// Removes a user from an account by deleting the user's document in Firestore.
export const removeFromAccount = (accountUserId) => {
  return accountUsersRef.doc(accountUserId).delete();
};

// Removes all users associated with a given account ID from Firestore.
export const removeByAccountId = (accountId) => {
  if (accountUsersListener[accountId]) {
    accountUsersListener[accountId]();
  }
  return new Promise((resolve, reject) => {
    accountUsersRef.where('account', '==', accountId)
      .get()
      .then((querySnapshot) => {
        // Remove each account-user entry associated with the provided account id
        const { docs } = querySnapshot;
        for (let x = 0, ln = docs.length; x < ln; x++) {
          accountUsersRef.doc(docs[x].id).delete();
        }
        resolve(true);
      })
      .catch((err) => {
        console.error(err);
        reject(err.message);
      })
  })
}
