//Redux
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

//Redux imports
import reducers from './reducers';
import { actions } from './actions';
import rootSaga from './sagas';

//Firebase
import fb from './firebase';
import { loadAllergens } from './firebase/allergens';

// Import and initialize Redux-Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the Redux store with reducers and middleware
const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
  );

// Run the root saga to start handling side effects
sagaMiddleware.run(rootSaga);

// Load allergens (initial data required for the application)
loadAllergens();

// Set up Firebase authentication listener to handle user login/logout events
fb.auth.onAuthStateChanged(user => {
  if (user) {
    console.log('user logged in ' + user.email)
    // Dispatch an action to set the user in the Redux state
    store.dispatch(actions.userSet(user));
    // Dispatch an action to load accounts associated with the user's email
    store.dispatch(actions.accounts.accountsLoadRequest(user.email));
  } else {
    // If no user is logged in:
    // Clear these
    store.dispatch(actions.userClear());
    store.dispatch(actions.profile.profileClear());
    store.dispatch(actions.accounts.accountsClear());
  }
});

export default store;
