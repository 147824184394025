//React
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';

//Components
import Page from '../../components/Page/Page';
import Dashboard from './Dashboard';
import Financials from './Financials';
import SalesTabs from './SalesTabs';
import CostsTabs from './CostsTabs';
import Receipts from './components/Receipts';

//Files
import { useDate } from '../../context/DateContext';
import useReportWeeks from '../../hooks/useReportWeeks';
import { getSalesData, getCostsData, getFinancialsData } from '../../utils/reports';
import { findFinancePeriod } from '../../utils/financials'
import { actions } from '../../store/actions';

const unsetHierarchicalFilters = (filterName, prevFilters) => {
  // unset filters hierarchically
  if (filterName === 'locations') {
    prevFilters['departments'] = [];
    prevFilters['areas'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'areas') {
    prevFilters['departments'] = [];
    prevFilters['categories'] = [];
  }
  if (filterName === 'departments') {
    prevFilters['categories'] = [];
  }
  return {...prevFilters};
}

const initFilters = {
  locations: [],
  areas: [],
  departments: [],
  categories: [],
  suppliers: [],
  ingredientCategories: []
}

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentAccount = useSelector((state) => state.currentAccount);
  const accountId = currentAccount;
  const account = useSelector(state => state.accounts[accountId]);

  const backgroundLoading = useSelector((state) => state.backgroundLoading);
  const [filters, setFilters] = useState(initFilters);
  const weekProps = useReportWeeks({});

  const accountsData = useSelector(state => state.reports.accountsData);
  /*const salesData = useSelector(state => state.reports.salesData);
  const costsData = useSelector(state => state.reports.costsData);
  const financialsData = useSelector(state => state.reports.financialsData);
  //console.log(accountsData, salesData, costsData, financialsData)*/

  const updateFilters = useCallback((filterName, value) => {
    setFilters(prevFilters => {
      // Shallow copy of previous filters
      let updatedFilters = { ...prevFilters };
      const currentValues = updatedFilters[filterName] || [];

      const index = currentValues.indexOf(value);
      if (index === -1) {
        updatedFilters[filterName] = [...currentValues, value];
      } else {
        updatedFilters[filterName] = currentValues.filter(item => item !== value);
      }

      // Ensure to call unsetHierarchicalFilters properly
      return unsetHierarchicalFilters(filterName, updatedFilters);
    });
  }, []);

  const filterProps = { filters, updateFilters }

  const weekdates = useMemo(() => {
    return weekProps.weekInfo;
  }, [weekProps.weekInfo]);

  //Financials
  const { currentYear: currentYearDateContext } = useDate();
  const [currentYear, setCurrentYear] = useState(currentYearDateContext);
  const currentYearProps = { currentYear, setCurrentYear }
  
  const financials = useSelector((state) => _.sortBy(state.financials[accountsData.accountId], 'year'));
  const financial = financials.find(f => parseInt(f.year) === parseInt(currentYear));
  const financialsProps = { financials, financial };

  const [currentPeriod, setCurrentPeriod] = useState(findFinancePeriod(financial)?.periodKey);
  const currentPeriodProps = { currentPeriod, setCurrentPeriod };
  
  //Old Hook - to keep for reference
  //const data = useReportData({ accountId, filters, weekProps, filterProps, supplierMap, ingredients, ingredientCategories });

  // UseEffect for loading data based on section
  useEffect(() => {
      const aggregatedSalesData = getSalesData(accountId, accountsData, weekdates, filterProps);
      //console.log(aggregatedSalesData)
      dispatch(actions.reports.salesDataSet(aggregatedSalesData));
      const aggregatedCostsData = getCostsData(accountId, accountsData, weekdates, filterProps);
      //console.log(aggregatedCostsData)
      dispatch(actions.reports.costsDataSet(aggregatedCostsData));
      const aggregatedFinancialsData = getFinancialsData(financial, accountsData, currentPeriod, currentYear, filterProps);
      //console.log(aggregatedFinancialsData)
      dispatch(actions.reports.financialsDataSet(aggregatedFinancialsData));
  }, [accountId, accountsData, weekdates, currentPeriod, currentYear, filters, dispatch]);

  // Redirect to the product page if backgroundLoading is true
  useEffect(() => {
    if (backgroundLoading) {
      navigate('/products'); // Adjust the path to your product page
    }
  }, [backgroundLoading, navigate]);

  // Check if the current account and component are valid before rendering
  if (!currentAccount) {
    return null;
  }

  return (
    <Page title="Reports" flex="1 0 auto">
      <Routes>
        <Route path="dashboard/*" element={<Dashboard account={account} accountId={accountId} accounts={accountsData} weekProps={weekProps} filterProps={filterProps} />} />
        <Route path="financials/*" element={<Financials account={account} accounts={accountsData} filterProps={{ filters, updateFilters }} financialsProps={financialsProps} currentYearProps={currentYearProps} currentPeriodProps={currentPeriodProps} />} />
        <Route path="sales/*" element={<SalesTabs account={account} accountId={accountId} accounts={accountsData} weekProps={weekProps} filterProps={filterProps} />} />
        <Route path="costs/*" element={<CostsTabs account={account} accountId={accountId} accounts={accountsData} weekProps={weekProps} filterProps={filterProps} />} />
        {account?.has_deliverect && (
          <Route path="orders/*" element={<Receipts account={account} accounts={accountsData} weekProps={weekProps} filterProps={{ filters, updateFilters }} />} />
        )}
      </Routes>
    </Page>
  );
};

Reports.propTypes = {
  accountId: PropTypes.string
};

export default Reports;
