//React//
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Libraries
import { majorScale, minorScale, Pane, SelectField, Text, useTheme } from 'evergreen-ui';
import _ from 'lodash';
import moment from 'moment';

//Components
import ReportFilters from './components/ReportFilters';
import Block from '../../components/ui/Block/Block';
import DataTable from '../../components/DataTable/DataTable'
import CustomDialog from '../../components/Dialog/Dialog';
import CustomHeading from '../../components/Headings/Headings';
import { CustomStackedChart } from '../../components/Recharts/Recharts'

//Files
import { aggregateFinanacialPeriods, aggregateFinancialPeriodsAreasData, buildPeriodsArray, filterPeriodsToNow } from '../../utils/financials';

const buildPeriodDateLabel = (period) => {
  if (!period) return 'Invalid period';
  return `${moment.utc(period.start, 'YYYY-MM-DD').format('DD/MM/YY')} to ${moment.utc(period.end, 'YYYY-MM-DD').format('DD/MM/YY')}`;
}

const buildWeekDateLabel = (week) => {
  if (!week) return 'Invalid week';
  return `Week ${week.num + 1}: ${week.weekStart.format('DD/MM')} to ${week.weekEnd.format('DD/MM')}`;
}

const buildYearPeriodLabel = (periodStart, periodEnd) => {
  if (!periodStart || !periodEnd) return 'Invalid period';
  return `${moment.utc(periodStart.start, 'YYYY-MM-DD').format('DD/MM/YY')} to ${moment.utc(periodEnd.end, 'YYYY-MM-DD').format('DD/MM/YY')}`;
}

const findPeriodNumFromPeriodKey = (currentPeriod) => {
  if (!currentPeriod) return -1;
  return parseInt(currentPeriod.split('_')[1]);
}


const Financials = ({ accounts, filterProps, financialsProps, currentYearProps, currentPeriodProps }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentPeriod, setCurrentPeriod } = currentPeriodProps
  const { currentYear, setCurrentYear } = currentYearProps
  const { financials, financial } = financialsProps

  const financialsData = useSelector(state => state.reports.financialsData);

  // Access year and date-related functions from DateContext
  const [periodsDataState, setPeriodsDataState] = useState(null);
  const [isDialogShown, setIsDialogShown] = useState(false); 

  const newAccounts = useMemo(() => ({ ...accounts, filterProps }), [accounts, filterProps]);

  useEffect(() => {
    if (!financial) {
      setIsDialogShown(true);
    }
  }, [financial]);

  const changeYear = useCallback((year) => {
    //Need to check, maybe need to put period_11
    setCurrentPeriod('period_0');
    setCurrentYear(year);
  }, []);

  //Select the areas as object with name and id
  //const areaCats = useSelector(state => _.keyBy(current(state, 'areas'), 'id'));
  
  useEffect(() => {
    if (financialsData) {
      console.log('Setting periodsDataState', financialsData); 
      setPeriodsDataState(financialsData);
    }
  }, [financialsData]);

  //This is used for the select period component
  const periods = financial ? buildPeriodsArray(financial) : null;

  // Memoize the aggregated data calculation to avoid recomputation on every render
  const aggregated = useMemo(() => {
    //console.log("Calculating aggregated data...");
    return aggregateFinancialPeriodsAreasData(periodsDataState, currentPeriod);
  }, [periodsDataState, currentPeriod]);
  //console.log(aggregated, 'AGGREGATED')

  const periodNum = currentPeriod ? findPeriodNumFromPeriodKey(currentPeriod) : -1;

  const totals = useMemo(() => {
    //console.log("Calculating totals...");
    return aggregateFinanacialPeriods(aggregated.periods, periodNum);
  }, [aggregated.periods, periodNum]);
  //console.log(totals, 'TOTOTOTOOTOT')
  
  const aggregatedPeriodData = aggregated?.periods[periodNum >= 0 ? periodNum : aggregated.periods.length - 1];
  //console.log(aggregatedPeriodData, 'EORERE')

  const summaryTableHeaders = [
    { label: 'Summary', field: 'label', type: 'text', width: aggregatedPeriodData?.budget ? 3 : 5 },
    { label: 'Actual', field: 'actual', type: 'numeric', prefix: '£', width: aggregatedPeriodData?.budget ? 3 : 3 },
    { label: '%', field: 'actualPercent', type: 'text', suffix:'%', width: aggregatedPeriodData?.budget ? 3 : 3 },
    { label: 'Th. Var. £', description: 'Theoretical Variance in £', field: 'thVar', type: 'numeric', prefix: '£', width: aggregatedPeriodData?.budget ? 3 : 3 },
    { label: '%', description: 'Theoretical Variance in %', field: 'thVarPercent', type: 'text', suffix:'%', width: aggregatedPeriodData?.budget ? 3 : 2 },
    ...(aggregatedPeriodData?.budget ? [
      { label: 'Bud. Var.', field: 'budgetVar', type: 'numeric', prefix: '£', width: 2 },
      { label: '%', field: 'budgetVarPercent', type: 'text', suffix:'%', width: 2 },
    ] : []),  // Conditionally include budget columns
  ];

  /*For testing purpose
  const summaryTableHeaders = [
    { label: 'Summary', field: 'label', type: 'text', width: 3  },
    { label: 'Actual', field: 'actual', type: 'numeric', prefix: '£', width:  3 },
    { label: '%', field: 'actualPercent', type: 'text', suffix:'%', width: 2 },
    { label: 'Th. Var. £', description: 'Theoretical Variance in £', field: 'thVar', type: 'numeric', prefix: '£', width:  2 },
    { label: '%', description: 'Theoretical Variance in %', field: 'thVarPercent', type: 'text', suffix:'%', width: 2 },
    { label: 'Bud. Var.', field: 'budgetVar', type: 'numeric', prefix: '£', width: 2 },
    { label: '%', field: 'budgetVarPercent', type: 'text', suffix:'%', width: 2 },
  ];*/

  const areaTableHeaders = [
    { label: 'Summary', field: 'label', type: 'text', width: aggregatedPeriodData?.budget ? 4 : 6 },
    { label: 'Actual', field: 'actual', type: 'numeric', prefix: '£', width: aggregatedPeriodData?.budget ? 3 : 4 },
    { label: '%', field: 'actualPercent', type: 'text', suffix:'%', width: aggregatedPeriodData?.budget ? 3 : 4 },
    //{ label: 'Th. Var. £', description: 'Theoretical Variance in £', field: 'thVar', type: 'numeric', prefix: '£', width: 2 },
    //{ label: '%', description: 'Theoretical Variance in %', field: 'thVarPercent', field: 'thVarPercent', type: 'text', suffix:'%', width: 2 },
    ...(aggregatedPeriodData?.budget ? [
      { label: 'Bud. Var.', field: 'budgetVar', type: 'numeric', prefix: '£', width: 3 },
      { label: '%', field: 'budgetVarPercent', type: 'text', suffix:'%', width: 3 },
    ] : []),
  ];

  const chartData = aggregatedPeriodData?.periods.map(period => {
    return period.weeks.map(week => ({
      week: `W${week.week.week.num + 1}: ${week.week.week.weekStart.format('DD/MM')}`,
      'Net sales': week.actualNetSales,
      'Cost of sales': week.actualCostOfSales,
      'Gross profit': week.actualGrossProfits,
      'GP %': week.actualGrossProfitsPercent,
    }));
  }).flat();
  
  const valueTypes = {
    'Net sales': 'currency',
    'Cost of sales': 'currency',
    'Gross profit': 'currency',
    'GP %': 'percentage'
  };

  return (
    <>
      <Block marginBottom={majorScale(2)}  display='flex' alignItems='center' justifyContent='start'>
        <Pane paddingX={majorScale(2)} paddingTop={majorScale(2)}>
          <SelectField
              label='Year'
              maxWidth={130}
              placeholder="Select year"
              value={currentYear}
              onChange={(e) => changeYear(e.target.value)}
              //padding={majorScale(2)}
          >
            {financials.reverse().map((obj) => (<option key={obj.year} value={obj.year}>{obj.year}</option>))}
          </SelectField>
        </Pane>
        {financial && (
          <Pane paddingX={majorScale(2)} paddingTop={majorScale(2)}>
            <SelectField
                label='Period'
                maxWidth={250}
                placeholder="Select period"
                value={currentPeriod}
                onChange={(e) => setCurrentPeriod(e.target.value)}
                //padding={majorScale(2)}
            >
              {filterPeriodsToNow(periods, financial).map((period, index) => {
                return (
                  <option key={`period_${index}`} value={`period_${index}`}>
                    Period {index + 1} - {buildPeriodDateLabel(period)}
                  </option>
                );
              })}
            </SelectField>
          </Pane>
        )}
      </Block>

      {financial && (
        <>
          <ReportFilters accounts={newAccounts} />

          <Pane display='flex' alignContent='center'>
            
            <Block width="100%" marginBottom={majorScale(2)} marginRight={majorScale(1)}>
              <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                  Period to date: {buildPeriodDateLabel(financial[currentPeriod])}
              </Text>
              <DataTable
                headers={summaryTableHeaders}
                items={[
                  {
                    label: 'Net Revenues',
                    actual: aggregatedPeriodData?.actualNetSales,
                    actualPercent: '100',
                    thVar: '',
                    thVarPercent: '0',
                    budgetVar: aggregatedPeriodData?.budgetVariance,
                    budgetVarPercent: aggregatedPeriodData?.budgetVariancePercent,
                  },
                  {
                    label: `${aggregatedPeriodData?.stockType} Cost of Sales`,
                    actual: aggregatedPeriodData?.actualCostOfSales,
                    actualPercent: aggregatedPeriodData?.actualCostOfSalesPercent,
                    thVar: aggregatedPeriodData?.thCostOfSalesVar,
                    thVarPercent: aggregatedPeriodData?.thCostOfSalesVarPercent,
                    budgetVar: aggregatedPeriodData?.thCostOfSales,
                    budgetVarPercent: aggregatedPeriodData?.thCostOfSalesPercent,
                  },
                  {
                    label: `${aggregatedPeriodData?.stockType} Gross Profit`,
                    actual: aggregatedPeriodData?.actualGrossProfits,
                    actualPercent: aggregatedPeriodData?.actualGrossProfitsPercent,
                    thVar: aggregatedPeriodData?.thGrossProfitVar,
                    thVarPercent: aggregatedPeriodData?.thGrossProfitVarPercent,
                    budgetVar: aggregatedPeriodData?.thGrossProfit,
                    budgetVarPercent: aggregatedPeriodData?.thGrossProfitPercent,
                  },
                ]}
                listHeight={300}
              />
            </Block>

            <Block width="100%" marginBottom={majorScale(2)} marginLeft={majorScale(1)}>
              <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                  Year to date: {buildYearPeriodLabel(periods[0], periods[periodNum])}
              </Text>
              <DataTable
                headers={summaryTableHeaders}
                items={[
                  {
                    label: 'Net Revenues',
                    actual: totals?.actualNetSales,
                    actualPercent: '100',
                    thVar: '',
                    thVarPercent: '0',
                    budgetVar: totals?.budgetVariance,
                    budgetVarPercent: totals?.budgetVariancePercent,
                  },
                  {
                    label: `${totals?.stockType} Cost of Sales`,
                    actual: totals?.actualCostOfSales,
                    actualPercent: totals?.actualCostOfSalesPercent,
                    thVar: totals?.thCostOfSalesVar,
                    thVarPercent: totals?.thCostOfSalesVarPercent,
                    budgetVar: totals?.thCostOfSales,
                    budgetVarPercent: totals?.thCostOfSalesPercent,
                  },
                  {
                    label: `${totals?.stockType} Gross Profit`,
                    actual: totals?.actualGrossProfits,
                    actualPercent: totals?.actualGrossProfitsPercent,
                    thVar: totals?.thGrossProfitVar,
                    thVarPercent: totals?.thGrossProfitVarPercent,
                    budgetVar: totals?.thGrossProfit,
                    budgetVarPercent: totals?.thGrossProfitPercent,
                  },
                ]}
                listHeight={300}
              />
            </Block>
          </Pane>
          
          <Block width={'100%'} overflowX="scroll" marginBottom={majorScale(2)} padding={majorScale(2)}>
            <CustomHeading level='3' padding={majorScale(2)}>Financial year by week</CustomHeading>
            <CustomStackedChart
              data={chartData}
              height={400}
              xKey="week"
              barKeys={['Net sales', 'Cost of sales']}
              lineKey="GP %"
              valueTypes={valueTypes}
              yDomain={[0, 'auto']}
              y2Domain={[0, 100]}
            />
          </Block>
            
          <Block width={'100%'} overflowX="scroll" marginBottom={majorScale(2)}>
            <Pane display="flex" alignItems="center">
              {aggregatedPeriodData?.weeks.map((week, index) => {
                return (
                  <Pane key={`week_${index}`} minWidth={400} marginRight={minorScale(2)}>
                    <Text display="flex" padding={majorScale(2)} fontWeight={500} justifyContent="flex-start" color={theme.colors.tertiary100}>
                      {buildWeekDateLabel(week.week.week)}

                    </Text>
                    <DataTable
                      headers={areaTableHeaders} // Use headers similar to the YTD table
                      items={[
                        {
                          label: 'Net Revenues',
                          actual: week.actualNetSales,
                          actualPercent: '100',
                          thVar: '0',
                          thVarPercent: '0',
                          budgetVar: week.budget,
                          budgetVarPercent: week.budgetVariancePercent,
                        },
                        {
                          label: `${week.stockType} Cost of Sales`,
                          actual: week.actualCostOfSales,
                          actualPercent: week.actualCostOfSalesPercent,
                          thVar: week.thCostOfSalesVar,
                          thVarPercent: week.thCostOfSalesVarPercent,
                          budgetVar: week.thCostOfSales,
                          budgetVarPercent: week.thCostOfSalesPercent,
                        },
                        {
                          label: `${week.stockType} Gross Profit`,
                          actual: week.actualGrossProfits,
                          actualPercent: week.actualGrossProfitsPercent,
                          thVar: week.grossProfitsThVar,
                          thVarPercent: week.grossProfitsThVarPercent,
                          budgetVar: week.grossProfitsBudgetVar,
                          budgetVarPercent: week.grossProfitsBudgetVarPercent,
                        },
                      ]}
                      listHeight={300}
                    />
                  </Pane>
                );
              })}
            </Pane>
          </Block>

          {/*<Block marginBottom={majorScale(2)}>
            <Text display='flex' padding={majorScale(2)} fontWeight={500} justifyContent='flex-start' color={theme.colors.tertiary100}>
                Period to date: {buildPeriodDateLabel(periods?.[findPeriodNumFromPeriodKey(currentPeriod)])}
            </Text>
            <DataTable
              headers={areaTableHeaders}
              items={_.map(aggregated.areaCats, (area, areaId) => {
                const areaPeriod = aggregated.periods[periodNum];
                if (!areaPeriod) return {};
                const areaBudgetSales = areaPeriod.budget && areaPeriod.netSales ? (100*(1 - (areaPeriod.budget / areaPeriod.netSales))) : 0;
                return {
                  areaName: areaCats[areaId]?.name,
                  actual: areaPeriod.netSales,
                  actualPercent: calcPercentage(areaPeriod.netSales, aggregatedPeriodData.netSales, 2),
                  budget: areaPeriod.budget,
                  budgetPercent: calcPercentage(areaPeriod.budget, aggregatedPeriodData.budget, 2),
                  budgetVar: areaPeriod.netSales - areaPeriod.budget,
                  budgetVarPercent: areaBudgetSales ? areaBudgetSales.toFixed(2): '',
                }
              })}
              listHeight={340}
            />
          </Block>*/}
        </>
      )}

      {!financial && (
        <Block>
          <Pane padding={majorScale(3)}>
            <Text style={{fontSize:"16px"}} >Please set your financials to use this feature.</Text>
          </Pane>
        </Block>
      )}

      <CustomDialog
        isOpen={isDialogShown}
        title="Financial Year Not Set"
        onCloseComplete={() => setIsDialogShown(false)} // Close the dialog
        confirmLabel="Set Financial Year"
        onConfirm={() => {
          setIsDialogShown(false); // Close the dialog
          
          navigate("/account?tab=financial-year"); 
        }}
      >
        <Text>
          Your financial year has not been set yet. To enjoy this feature, please set your financial year. We are using the 4-4-5 financial year model, which is the most common for comparing year on year performance. Feel free to adjust the period to your needs if necessary.
        </Text>
      </CustomDialog>
    </>
  );
}

export default Financials;
