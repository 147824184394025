import _ from 'lodash';
import store from '../redux';
import fb from './';
import { actions } from '../actions';

let accountUsersListener
const accountListeners = {}
const accountUsersRef = fb.firestore.collection('accountUsers')

const unlistenAccounts = () => {
  for (let id in accountListeners) {
    accountListeners[id]();
    delete accountListeners[id];
  }
};

// exported methods & fields
export const accountsRef = fb.firestore.collection('accounts')

/**
 * Adds a Firestore listener for a specific account and recursively sets up listeners for its children.
 * 
 * ### Key Behavior:
 * 1. **Real-time Updates**:
 *    - A listener is added to the specified `accountId` and recursively to all child accounts.
 *    - Ensures all accounts in the hierarchy receive real-time updates.
 * 
 * 2. **Recursive Listener Addition**:
 *    - For each child account in the `children` array, this function calls itself to add a listener.
 *    - Recursion stops when an account has no `children` or its `children` array is empty.
 * 
 * 3. **Duplicate Prevention**:
 *    - Before adding a listener for a child account, the function checks if a listener already exists using `accountListeners`.
 * 
 * ### Future Improvement Plan:
 * **Separate Listener Management for Main and Related Accounts:**
 * - **Phase 1: Initial Setup**
 *   - Attach listeners only for main accounts that are either `OWNER` or `LOCATION`.
 *   - Ensure the initial app load is lightweight and focuses only on essential data.
 * 
 * - **Phase 2: Dynamic Listener Management**
 *   - When a user selects a specific account (e.g., switches `currentAccount` or `workingAccount`):
 *     - Dynamically add listeners for all related accounts (e.g., `GROUP`, `DEPARTMENT`, or child accounts).
 *     - Leverage `getAccessibleAccounts2` to determine the precise hierarchy for the selected account.
 * 
 * ### Advantages of This Approach:
 * 1. **Optimized Performance**:
 *    - Reduces Firestore reads and active listeners during initial app load.
 *    - Focuses resources on accounts the user is actively interacting with.
 * 
 * 2. **Scalability**:
 *    - Prevents potential performance bottlenecks in hierarchies with many child accounts.
 *    - Ensures the app remains responsive even with complex account structures.
 * 
 * 3. **Improved Access Control**:
 *    - Dynamically adapt listener management to match the user's permissions and current focus.
 * 
 * ### Implementation Notes:
 * - Update the logic in `addAccountListener` to distinguish between "main" accounts (OWNER, LOCATION) and related accounts (GROUP, DEPARTMENT, children).
 * - Modify the account accessibility update flow to trigger related account listeners when the user selects a specific account.
 * 
 * ### Current Limitations:
 * - Adds listeners recursively for all children, which may lead to unnecessary listeners in certain scenarios.
 * - Users with access to a `LOCATION` may not receive updates for related `GROUP` or `OWNER` accounts if those accounts are not directly linked.
 * 
 * @param {string} accountId - The ID of the account to add a listener for.
 */
const addAccountListener = (accountId) => {
  //console.log(`[addAccountListener] Adding listener for account: ${accountId}`);
  store.dispatch(actions.accounts.accountLoadRequest(accountId));
  store.dispatch(actions.applicationLoadStart(accountId));

  const accountListener = accountsRef.doc(accountId).onSnapshot((doc) => {
    const account = { ...doc.data(), id: doc.id };

    if (account.children) {
      //console.log(`[addAccountListener] Account ${accountId} has children: ${account.children}`);
      _.forEach(account.children, (childAccountId) => {
        if (!accountListeners[childAccountId]) {
          //console.log(`[addAccountListener] Adding listener for child account: ${childAccountId}`);
          addAccountListener(childAccountId);
        }
      });
    }

    if (!account.deleted) {
      //console.log(`[addAccountListener] Dispatching accountLoaded for account: ${accountId}`);
      store.dispatch(actions.accounts.accountLoaded(account));
    }

    //console.log(`[addAccountListener] Dispatching applicationLoadComplete for account: ${accountId}`);
    store.dispatch(actions.applicationLoadComplete(accountId));
  });

  accountListeners[accountId] = accountListener;
  //console.log(`[addAccountListener] Listener registered for account: ${accountId}`);
};

const setAccountNull = () => {
  store.dispatch(actions.applicationLoaded());
  store.dispatch(actions.profile.profileLoadRequest());
}

/**
 * Loads all account documents associated with the provided user email.
 * Sets up listeners for each account and updates the Redux state with account data.
 * @param {string} userEmail - The email of the user whose accounts are being loaded.
 */
export const loadAccounts = (userEmail) => {
  if (accountUsersListener) { 
    accountUsersListener() 
  }
  unlistenAccounts()
  store.dispatch(actions.accounts.accountsClear());

  console.log(`loading: accounts using accountUsers`)
  accountUsersListener = accountUsersRef
    .where('email', '==', userEmail)
    .onSnapshot((querySnapshot) => {
      console.log(`Received account user data for email: ${userEmail}`);
      querySnapshot.forEach((audoc) => {
        const accountUser = audoc.data();
        //console.log(`Processing account user: ${accountUser.account}`);
        if (!accountListeners[accountUser.account]) {
          //console.log(`Adding listener for account: ${accountUser.account}`);
          addAccountListener(accountUser.account);
        }
      });
    });
  if (_.isEmpty(accountListeners)) setAccountNull();
}

// Removes all event listeners and clears cached data for account documents.
export const clearAccount = (accountId) => {
  if (accountListeners[accountId]) {
    accountListeners[accountId]();
  }
};

// This function removes all active listeners for accounts and clears any cached data.
export const clearAccounts = () => {
  unlistenAccounts()
}

// Adds a new account document to Firestore and returns the reference.
export const addAccount = (account) => {
  return accountsRef.add(account)
}

// Removes an account document from Firestore.
export const removeAccount = (accountId) => {
  return accountsRef.doc(accountId).delete()
}

// Updates a specific account document in Firestore with new data.
export const updateAccount = (accountId, account) => {
  return accountsRef.doc(accountId).update(account)
}
