import _ from 'lodash';
import store from '../redux';
import { actions } from '../actions';
import { accountsRef } from './accounts';

const collectionUnsubs = {};
const collectionRefs = {};

// Sets up a listener for changes to a specific Firestore collection within an account.
export const loadCollection = (collectionName, accountId) => {
  // Unique identifier for this collection within the account
  const identifier = `${accountId}-${collectionName}`;

  // If there's an existing listener, unsubscribe first to prevent memory leaks or duplicate listeners.
  if (collectionUnsubs[identifier]) {
    collectionUnsubs[identifier]();
  }

  // Dispatch an action indicating the start of loading this collection.
  store.dispatch(actions.applicationLoadStart(`${accountId}/${collectionName}`));
  store.dispatch(actions.applicationBackgroundStart(`${accountId}/${collectionName}`));

  // Access the specific Firestore collection for the account.
  collectionRefs[identifier] = accountsRef.doc(accountId).collection(collectionName);

  // Set up a listener for real-time updates to this collection.
  collectionUnsubs[identifier] = collectionRefs[identifier]
    .onSnapshot((querySnapshot) => {
      const results = [];
      // Iterate over each document in the collection, adding it to the results array.
      querySnapshot.forEach((doc) => {
        results.push({ id: doc.id, ...doc.data() });
      });
      // Dispatch an action to update the Redux store with the new data.
      store.dispatch(actions[collectionName][`${collectionName}LoadSuccess`](results, accountId));
      // Dispatch an action indicating the completion of the load process.
      store.dispatch(actions.applicationLoadComplete(`${accountId}/${collectionName}`));
      store.dispatch(actions.applicationBackgroundComplete(`${accountId}/${collectionName}`));
    });
};

// Clears any active listeners and Redux state for a specific collection.
export const clearCollection = (collectionName) => {
  const keys = _.filter(
    _.keys(collectionUnsubs),
    (key) => _.endsWith(key, `-${collectionName}`)
  );
  for (let x = 0, ln = keys.length; x < ln; x++) {
    if (collectionUnsubs[keys[x]]) {
      collectionUnsubs[keys[x]]();
      delete collectionUnsubs[keys[x]];
    }
  }
  store.dispatch(actions[collectionName][`${collectionName}Clear`]());
};

// Clears all collections associated with a specific account from listeners and Redux state.
export const clearAccountCollections = (accountId) => {
  const keys = _.filter(
    _.keys(collectionUnsubs),
    (key) => _.startsWith(key, `${accountId}-`)
  );
  for (let x = 0, ln = keys.length; x < ln; x++) {
    if (collectionUnsubs[keys[x]]) {
      collectionUnsubs[keys[x]]();
      delete collectionUnsubs[keys[x]];
    }
  }
};

// Retrieves a reference to a specific Firestore collection for an account.
export const getCollectionRef = (collectionName, accountId) => {
  const identifier = `${accountId}-${collectionName}`;
  if (collectionRefs[identifier]) {
    return collectionRefs[identifier];
  }
  
  return false;
};
