import { all, takeEvery, select, put, call, fork } from 'redux-saga/effects';
import { toaster } from 'evergreen-ui';
import _ from 'lodash';

import { accountTypes, messageTypes } from '../../utils/constants';
import { getAccessibleAccountIds2, getAccessibleAccountIds, findOwnerAccount } from '../../utils/accounts';
import { actionTypes, actions } from '../actions';
import { loadCollection, clearCollection, clearAccountCollections } from '../firebase/common';
import { loadAccounts, updateAccount, addAccount, clearAccount, clearAccounts } from '../firebase/accounts';
import { loadProfile, updateProfile, addProfile } from '../firebase/profile';
import { loadAccountUsers, clearAccountUsers, addToAccount, removeFromAccount, removeByAccountId } from '../firebase/accountUsers';

import foodRecordsSaga from './foodRecords';
import orderRecordsSaga from './orderRecords';
import invoiceUploadsSaga from './invoiceUploads';
import ingredientsSaga from './ingredients';
import ingredientCategoriesSaga from './ingredientCategories';
import supplierCategoriesSaga from './supplierCategories';
import recipesSaga from './recipes';
import recipeCategoriesSaga from './recipeCategories';
import modifiersSaga from './modifiers';
import modifierCategoriesSaga from './modifierCategories';
import departmentsSaga from './departments';
import menuCategoriesSaga from './menuCategories';
import suppliersSaga from './suppliers';
import supplierOptionsSaga from './supplierOptions';
import sectionsSaga from './sections';
import messagesSaga from './messages';
import messagesSeenTimesSaga from './messagesSeenTimes';
import miseEnPlacesSaga from './miseEnPlaces';
import stockTakeSaga from './stockTake';
import wastageSaga from './wastage';
import transferSaga from './transfer';
import ordersSaga from './orders';
import taskSaga from './tasks';
import tasksCommentsSaga from './tasksComments';
import taskHistorySaga from './tasksHistory';
import prepTaskSaga from './prepTasks';
import menusSaga from './menus';
import salesSaga from './sales';
import receiptsSaga from './receipts';
import reportsCommentsSaga from './reportsComments';
import reportsIncidentalsSaga from './reportsIncidentals';
import areasSaga from './areas';
import financialsSaga from './financials';
import budgetsSaga from './budgets';

const clearCollections = () => {
  clearAccountUsers();
  clearCollection('ingredients');
  clearCollection('ingredientCategories');
  clearCollection('supplierCategories');
  clearCollection('recipes');
  clearCollection('recipeCategories');
  clearCollection('modifiers');
  clearCollection('modifierCategories');
  clearCollection('departments');
  clearCollection('areas');
  clearCollection('financials');
  clearCollection('budgets');
  clearCollection('menus');
  clearCollection('menuCategories');
  clearCollection('deliveryCompanies');
  clearCollection('foodRecords');
  clearCollection('orderRecords');
  clearCollection('invoiceUploads');
  clearCollection('miseEnPlaces');
  clearCollection('suppliers');
  clearCollection('supplierOptions');
  clearCollection('sections');
  clearCollection('uploads');
  clearCollection('messages');
  clearCollection('messagesSeenTimes');
  clearCollection('wastages');
  clearCollection('transfers');
  clearCollection('stockTake');
  clearCollection('orderAttachments');
  clearCollection('orders');
  clearCollection('tasks');
  clearCollection('tasksComments');
  clearCollection('tasksHistory');
  clearCollection('prepTasks');
  clearCollection('sales');
  clearCollection('receipts');
  clearCollection('reportsComments');
  clearCollection('reportsIncidentals');
};

const loadCollectionsForParentsAndGroups = (accountId) => {
  // Parent Collections
  loadCollection('ingredients', accountId);
  loadCollection('ingredientCategories', accountId);
  loadCollection('suppliers', accountId);
  loadCollection('supplierOptions', accountId);
  loadCollection('supplierCategories', accountId);
  loadCollection('areas', accountId);
  loadCollection('departments', accountId);
  loadCollection('financials', accountId);
  loadCollection('uploads', accountId);
  loadCollection('messagesSeenTimes', accountId);

  // Group Collections
  loadCollection('menus', accountId);
  loadCollection('menuCategories', accountId);
  loadCollection('recipes', accountId);
  loadCollection('recipeCategories', accountId);
  loadCollection('modifiers', accountId);
  loadCollection('modifierCategories', accountId);
};

const loadCollectionsForSuppliers = (accountId) => {
  // Supplier Collections
  loadCollection('suppliers', accountId);
  loadCollection('supplierOptions', accountId);
  loadCollection('supplierCategories', accountId);
  loadCollection('uploads', accountId);
};

const loadCollectionsForCurrentAccount = (accountId) => {
  // Location
  loadCollection('invoiceUploads', accountId);

  // Area Operates Review
  loadCollection('budgets', accountId);
  loadCollection('sales', accountId);
  loadCollection('receipts', accountId);
  loadCollection('reportsIncidentals', accountId);
  loadCollection('reportsComments', accountId);
  loadCollection('orders', accountId);
  loadCollection('orderAttachments', accountId);
  loadCollection('stockTake', accountId);
  loadCollection('wastages', accountId);
  loadCollection('transfers', accountId);
};

export const loadCollectionsForWorkingAccount = (accountId) => {
  // Tasks and Others
  loadCollection('sections', accountId);
  loadCollection('prepTasks', accountId);
  loadCollection('deliveryCompanies', accountId);
  loadCollection('foodRecords', accountId);
  loadCollection('orderRecords', accountId);
  loadCollection('tasks', accountId);
  loadCollection('tasksComments', accountId);
  loadCollection('tasksHistory', accountId);

  loadCollection('miseEnPlaces', accountId);
  loadCollection('messages', accountId);
};

const loadingTasks = [];
const backgroundTasks = [];
const accountsLoading = [];

// Responds to account load requests, fetching user's accounts from Firestore.
function* accountsSaga() {

  // Listens for a request to load all accounts associated with a user's email and calls the loadAccounts function.
  yield takeEvery(actionTypes.accounts.ACCOUNTS_LOAD_REQUESTED, function* (action) {
    console.log('accounts load')
    try {
      yield call(loadAccounts, action.userEmail);
      yield put(actions.applicationBackgroundLoading());
      console.log('accounts load end')
    }
    catch(err) {
      console.log(err,'err account load')
    }
  });

  // Handles requests to clear all account information from the Redux store.
  yield takeEvery(actionTypes.accounts.ACCOUNTS_CLEAR, function* (action) {
    yield call(clearAccounts);
  });

  // This saga listens for the ACCOUNT_LOAD_REQUEST action and adds the accountId to the accountsLoading array.
  // This is used to track which accounts are currently being loaded.
  yield takeEvery(actionTypes.accounts.ACCOUNT_LOAD_REQUEST, function* (action) {
    yield accountsLoading.push(action.accountId);
  });

  // This saga listens for the ACCOUNT_LOADED action, which is dispatched once an account is fully loaded.
  // It triggers loading of associated account users and updates the loading state.
  // TODO: Update this logic to:
  // 1. Only listen to AccountUsers when the `currentAccount` is not null.
  // 2. Restrict listeners to accessibleAccounts derived from `getAccessibleAccountIds2`.
  // 3. Optimize Firestore usage by limiting listeners dynamically based on the user's active context.
  // 4. Add appropriate unit tests and verify changes with performance benchmarks.
  yield takeEvery(actionTypes.accounts.ACCOUNT_LOADED, function* (action) {
    const accountId = action.payload.id; // Extract the accountId from the payload
    try {
      // Start loading users associated with this account in parallel using the loadAccountUsers function
      yield fork(loadAccountUsers, accountId);
      
      // Remove the accountId from the accountsLoading array, indicating it has finished loading
      _.pull(accountsLoading, accountId);
  
      // If there are no accounts left in the accountsLoading array, trigger a profile load request
      if (accountsLoading.length === 0) {
        // Use fork to initiate a separate saga to load the user's profile
        yield fork(function* () {
          yield put(actions.profile.profileLoadRequest()); // Dispatch profileLoadRequest action
        });
      }
    } catch (err) {
      // Log any errors that occur and notify the user of the failure
      console.error(err, action);
      yield put(actions.appMessageError('Failed to load account data')); // Dispatch an error message
    }
  });

  // Clears specific account data.
  yield takeEvery(actionTypes.accounts.ACCOUNT_CLEAR, function* (action) {
    const accountId = action.payload;
    try {
      yield call(clearAccount, accountId);
      yield call(clearAccountCollections, accountId);
    }
    catch (err) {
      console.error(err, action);
      yield put(actions.appMessageError('Failed to clear account data'));
    }
  });

  // Updates account information in Firestore.
  yield takeEvery(actionTypes.accounts.ACCOUNT_UPDATE, function* (action) {
    try {
      const { callback, accountInfo } = action;
      const { id, ...moreInfo } = accountInfo;
      yield call(updateAccount, id, moreInfo);
      yield put(actions.appMessageSuccess('Account updated'));
      if (callback) {
        yield call(callback, accountInfo);
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Account could not be updated: ' + err.message));
    }
  });

  // Adds a new account to Firestore.
  yield takeEvery(actionTypes.accounts.ACCOUNT_CREATE, function* (action) {
    try {
      const { accountInfo, callback } = action;
      const accountDoc = yield call(addAccount, accountInfo);
      yield put(actions.accountUsers.addUser({ email: accountInfo.ownerEmail, account: accountDoc.id }));
      yield put(actions.appMessageSuccess('Account Added'));
      if (callback) {
        yield call(callback, { id: accountDoc.id, ...accountInfo });
      }
    }
    catch (err) {
      yield put(actions.appMessageError('Account could not be added: ' + err.message));
    }
  });

  // Handles adding a user to an account.
  yield takeEvery(actionTypes.accountUsers.ADD_USER, function* (action) {
    try {
      yield call(addToAccount, action.userInfo);
      yield put(actions.appMessageSuccess('Account User added'));
    }
    catch (err) {
      yield put(actions.appMessageError('User could not be added to account: ' + err.message));
    }
  });

  // Handles removing a user from an account.
  yield takeEvery(actionTypes.accountUsers.REMOVE_USER, function* (action) {
    try {
      yield call(removeFromAccount, action.userId);
      yield put(actions.appMessageSuccess('Account User removed'));
    }
    catch (err) {
      yield put(actions.appMessageError('User could not be removed from account: ' + err.message));
    }
  });
  //
  // Removes all users associated with an account.
  yield takeEvery(actionTypes.accountUsers.REMOVE_ACCOUNT, function* (action) {
    try {
      yield call(removeByAccountId, action.accountId);
      yield put(actions.appMessageSuccess('Account Users removed successfully.'));
    }
    catch (err) {
      console.error(err);
      yield put(actions.appMessageError('Users could not be removed from account: ' + err.message));
    }
  });
}

function* profileSaga() {
  // Listens for PROFILE_LOAD_REQUESTED actions and loads the user profile.
  yield takeEvery(actionTypes.profile.PROFILE_LOAD_REQUESTED, function* (action) {
    //console.log('PROFILE_LOAD_REQUESTED initiated for user:', action);
    const user = yield select((state) => (state.user));
    //console.log('Selected user state:', user);
    yield call(loadProfile, user);
  });

  // After loading the profile successfully, this saga loads additional related collections in parallel.
  yield takeEvery(actionTypes.profile.PROFILE_LOAD_SUCCESS, function* (action) {
    const { currentaccount, uid } = action.payload;

    try {
      // Start by dispatching an action indicating that profile-related loading is starting
      yield put(actions.applicationLoading());
      //yield put(actions.applicationLoadStart(`profile:${uid}`));
      yield put(actions.applicationBackgroundLoading());

      // Fetch accessible accounts from Redux state
      const accessibleAccounts = yield select((state) => state.accessibleAccounts);
      

      if (!accessibleAccounts || accessibleAccounts.length === 0) {
        console.warn('[PROFILE_LOAD_SUCCESS] No accessible accounts found.');
        yield put(actions.applicationLoadComplete(`profile:${uid}`));
        return;
      }

      yield put(actions.applicationLoadComplete(`profile:${uid}`));

      // Step 0: For Suppliers
      const currentAccountType = yield select(
        (state) => state.accounts[currentaccount]?.type
      );

      if (currentAccountType === 'SUPPLIER') {
        console.log(`[PROFILE_LOAD_SUCCESS] Loading collections for SUPPLIER account: ${currentaccount}`);
        loadCollectionsForSuppliers(currentaccount);
        // Complete load process for SUPPLIER and exit
        return; // Exit after SUPPLIER collections are loaded
      }

      // Step 1: Load OWNER and GROUP accounts first
      const ownerAndGroupAccounts = accessibleAccounts.filter(
        (account) => account.type === 'OWNER' || account.type === 'GROUP'
      );
      for (const account of ownerAndGroupAccounts) {
        loadCollectionsForParentsAndGroups(account.id);
      }

      // Step 2: Handle loading based on currentAccount type
      if (currentAccountType === 'LOCATION') {
        loadCollectionsForCurrentAccount(currentaccount);

        const childrenAccounts = accessibleAccounts.filter(
          (account) => account.parentLocation === currentaccount
        );
        for (const child of childrenAccounts) {
          loadCollectionsForCurrentAccount(child.id);
          loadCollectionsForWorkingAccount(child.id);
        }
      }
        
      // Step 3: Background loading for all remaining LOCATION accounts and their children
      //console.log('[PROFILE_LOAD_SUCCESS] Starting background');
      yield call(function* () {
        //yield put(actions.applicationBackgroundStart(`profile:${uid}-background`));
        const loadedAccounts = new Set(); // Track loaded accounts

        const loadAccountAndChildren = function* (accountId) {
          if (!loadedAccounts.has(accountId)) {
            //console.log(`[PROFILE_LOAD_SUCCESS] Loading collections for LOCATION: ${accountId}`);
            yield call(loadCollectionsForCurrentAccount, accountId);
            loadedAccounts.add(accountId);

            const childrenOfLocation = accessibleAccounts.filter(
              (account) => account.parentLocation === accountId
            );

            for (const child of childrenOfLocation) {
              yield call(loadAccountAndChildren, child.id);
            }
          }
        };

        const locationAccounts = accessibleAccounts.filter(
          (account) => account.type === 'LOCATION'
        );

        for (const location of locationAccounts) {
          yield call(loadAccountAndChildren, location.id);
        }
        //console.log('[PROFILE_LOAD_SUCCESS] Background loading for remaining LOCATION accounts complete.');
        yield put(actions.applicationBackgroundComplete(`profile:${uid}-background`));
      });
      
    } catch (err) {
      console.error('[PROFILE_LOAD_SUCCESS] Error loading collections:', err);
      yield put(actions.appMessageError('Unable to load collections from Profile load.'));
    }
  });

  yield takeEvery(actionTypes.profile.PROFILE_CLEAR, function* () {
    // When we clear the profile, clear the collections
    yield call(clearCollections);
    yield put(actions.applicationLoaded());
  });

  // Sets the current account in the profile.
  yield takeEvery(actionTypes.profile.PROFILE_SET_ACCOUNT, function* (action) {
    // Set the current account, and update the profile
    yield put(actions.accounts.currentAccountSet(action.accountId));
    const profile = yield select((state) => (state.profile));
    //console.log('1st step to be done PROFILE')
    profile.currentaccount = action.accountId;
    //yield call(updateProfile, profile.id, profile); //SEEMS TO WORK, to double check
    yield put(actions.profile.profileUpdate(profile));
  });

  // Updates the user profile.
  yield takeEvery(actionTypes.profile.PROFILE_UPDATE, function* (action) {
    const { id, ...profile } = action.profile;
    try {
      yield call(updateProfile, id, profile);
      //yield put(actions.profile.profileUpdateSuccess({ id, ...profile }));
      yield put(actions.appMessageSuccess('Profile updated'));

    } catch (err) {
      console.error('Profile update error', err);
      yield put(actions.appMessageError('Unable to update profile'));
    }
  });

  // Adds a new profile.
  yield takeEvery(actionTypes.profile.PROFILE_ADD, function* (action) {
    try {
      yield call(addProfile, action.profileId, action.profile);
      yield put(actions.appMessageSuccess('Account Created'));
    }
    catch (err) {
      console.error('Profile add error', err);
      yield put(actions.appMessageError('Unable to add profile'));
    }
  });
}

// Listens for the APP_LOAD_STARTED action to track loading tasks.
function* appSaga() {
  yield takeEvery(actionTypes.APP_LOAD_STARTED, function* (action) {
    // Logs the task that has started loading and adds it to the loadingTasks array.
    //For debugging purpose
    //console.log(action.task,'action task load')
    yield loadingTasks.push(action.task);
  });

  // Listens for the APP_LOAD_COMPLETE action to remove tasks from the loading array.
  yield takeEvery(actionTypes.APP_LOAD_COMPLETE, function* (action) {
    const uid = yield select(
      (state) => state.profile.uid
    );
    // Removes the completed task from the loadingTasks array.
    _.pull(loadingTasks, action.task);

    // If there are no more loading tasks, the application is considered fully loaded.
    if (loadingTasks.length === 0) {
      console.log('=== APPLICATION LOADED ===');
      // Dispatches an action to indicate that the application has fully loaded.
      yield put(actions.applicationLoaded());
    }
  });

  yield takeEvery(actionTypes.APP_BACKGROUND_STARTED, function* (action) {
    //console.log(action.task, 'Background task started');
    yield backgroundTasks.push(action.task);
  });

  yield takeEvery(actionTypes.APP_BACKGROUND_COMPLETE, function* (action) {
    //console.log(action.task, 'Background task completed');
    _.pull(backgroundTasks, action.task);

    if (backgroundTasks.length === 0) {
      console.log('=== BACKGROUND TASKS LOADED ===');
      yield put(actions.applicationBackgroundLoaded());
    }
  });

   // Listens for APP_MESSAGE_SET actions to display messages using the toaster.
  yield takeEvery(actionTypes.APP_MESSAGE_SET, function* (action) {
    switch (action.messageType) {
      case messageTypes.SUCCESS:
        yield call(toaster.success, action.messageText);
        break;
      case messageTypes.WARNING:
        yield call(toaster.warning, action.messageText);
        break;
      case messageTypes.ERROR:
        yield call(toaster.danger, action.messageText);
        break;
      case messageTypes.INFO:
      default:
        yield call(toaster.notify, action.messageText);
        break;
    }
  });

  // Listens for APP_MESSAGE_CLEAR actions to close all open toast notifications.
  yield takeEvery(actionTypes.APP_MESSAGE_CLEAR, function* () {
    yield call(toaster.closeAll);
  });
}


export default function* rootSaga() {
  yield all([
    appSaga(),
    accountsSaga(),
    profileSaga(),
    foodRecordsSaga(),
    orderRecordsSaga(),
    invoiceUploadsSaga(),
    ingredientsSaga(),
    ingredientCategoriesSaga(),
    supplierCategoriesSaga(),
    recipesSaga(),
    recipeCategoriesSaga(),
    modifiersSaga(),
    modifierCategoriesSaga(),
    departmentsSaga(),
    areasSaga(),
    financialsSaga(),
    budgetsSaga(),
    menuCategoriesSaga(),
    suppliersSaga(),
    supplierOptionsSaga(),
    sectionsSaga(),
    menusSaga(),
    messagesSaga(),
    messagesSeenTimesSaga(),
    miseEnPlacesSaga(),
    stockTakeSaga(),
    wastageSaga(),
    transferSaga(),
    ordersSaga(),
    taskSaga(),
    tasksCommentsSaga(),
    taskHistorySaga(),
    prepTaskSaga(),
    salesSaga(),
    receiptsSaga(),
    reportsCommentsSaga(),
    reportsIncidentalsSaga(),
  ]);
};
