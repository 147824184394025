import store from '../redux';
import fb from './'
import { actions } from '../actions';
import { getAccessibleAccountIds2 } from '../../utils/accounts';
import _ from 'lodash';

// Reference to the 'profiles' collection in Firestore
const profileRef = fb.firestore.collection('profiles')
let profileListener

// Function to create a new profile with default values based on user data
const createNewProfile = (user) => {
  const profile = {
    currentaccount: null,
    email: user.email,
    name: user.displayName,
    provider: 'google',
    uid: user.uid
  }
  addProfile(user.uid, profile)
  profile.id = user.uid
  return profile
}

// exported methods
// Function to load a user's profile from Firestore
export const loadProfile = (user) => {
  if (!user || !user.uid) {
    console.error('Whoops - incomplete user info provided!', user);
    return;
  }

  if (profileListener) {
    profileListener(); // Unsubscribe from any existing profile listener//
  }

  console.log(`loading: profile/${user.uid}`)
  store.dispatch(actions.applicationLoadStart(`profile:${user.uid}`)); // Indicates loading start

  // Sets up a real-time listener for the user's profile document in Firestore
  profileListener = profileRef.doc(user.uid).onSnapshot((doc) => {
    const profile = (doc.exists) ? { id: doc.id, ...doc.data() } : createNewProfile(user);
    const currentaccount = profile.currentaccount;

    // If currentaccount exists, calculate accessible accounts
    if (currentaccount) {
      const accessibleAccounts = getAccessibleAccountIds2(currentaccount); // Calculate accessible accounts
      const currentAccessibleAccounts = store.getState().accessibleAccounts; // Get current accessible accounts from Redux state

      //console.log(accessibleAccounts)
      //console.log(currentAccessibleAccounts)

      // Check if accessible accounts are equal by both ID and type
      const areAccessibleAccountsEqual = _.isEqual(
        _.map(currentAccessibleAccounts, (account) => _.pick(account, ['id', 'type'])),
        _.map(accessibleAccounts, (account) => _.pick(account, ['id', 'type']))
      );

      //console.log('[loadProfile] Accessible Accounts:', accessibleAccounts);
      console.log('[loadProfile] Are accessible accounts equal:', areAccessibleAccountsEqual);

      if (!areAccessibleAccountsEqual) {
        // Update the accessible accounts in Redux state
        store.dispatch(actions.accounts.accessibleAccountsSet(accessibleAccounts));
        // Dispatch actions for successful profile loading and setting the current account
        store.dispatch(actions.accounts.currentAccountSet(currentaccount));
        store.dispatch(actions.profile.profileLoadSuccess(profile));
      } else {
        console.log('[loadProfile] Accessible accounts unchanged, skipping reload.');
        // Dispatch only the profile success without reloading accounts
        //store.dispatch(actions.profile.profileLoadSuccess(profile));
      }
    } else {
      // Dispatch profile success without accessible accounts
      store.dispatch(actions.profile.profileLoadSuccess(profile));
    }
  });
};

// Function to clear the profile data from the application state
export const clearProfile = () => {
  store.dispatch(actions.profile.profileClear());
}

// Function to add a profile to Firestore
export const addProfile = (profileId, profile) => {
  // Adds or merges the profile document in Firestore
  return profileRef.doc(profileId).set(profile, {merge: true})
}

// Function to remove a profile from Firestore
export const removeProfile = (profileId) => {
  return profileRef.doc(profileId).delete()
}

// Function to update a profile in Firestore
export const updateProfile = (profileId, profile) => {
  return profileRef.doc(profileId).update(profile)
}
